<template>
  <ViewsCard />
</template>

<script>
import ViewsCard from '@/components/ViewsCard';

export default {
  components: {
    ViewsCard
  }
};
</script>
